import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { isMobile } from "../utils"
import Button from "./button"
import { title1, title2_Emphasis } from "./global-styles"
import IntlText from "./intl-text"
import { StaticImage, } from "gatsby-plugin-image";
import { breakpoints, colors } from "../theme"
import { CONTACT_US_ALT } from "../constants/Constants"
import "./home-contact.scss"

const Wrapper = styled.div`
  overflow-x: hidden;
  background-color: ${colors.pink};
  color: ${colors.black};
  padding-top: 60px;
  margin-top: ${props => (props.noMargin ? "0px!important" : "120px")};
  padding-bottom: 77px;
  & .contact-button {
    margin-top: 31px;
  }
  @media (min-width: ${breakpoints.medium}) {
    padding: 54px 0;
    margin-top: 87px;
    & .contact-button {
      margin-top: 39px;
    }
  }
  @media (min-width: ${breakpoints.extralarge}) {
    padding: 0 0 54px;
    margin-top: 100px;
  }
`

const Text = styled.h3`
  ${title2_Emphasis};
  @media (min-width: ${breakpoints.medium}) {
    ${title1};
    white-space: break-spaces;
  }
`

const Image = styled.img`
  width: 100%;
  margin-top: 80px;
  margin-right: -140px;
  display: block;

  @media (min-width: ${breakpoints.medium}) {
    margin-top: 0;
    margin-right: 0;
  }
`

const HomeContact = ({ noMargin }) => {
  return (
    <Wrapper noMargin={noMargin}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-start">
            <Text>
              <IntlText id="pages.home.contact.title" />
            </Text>
            <div className="contact-button">
              <Link to="/contact">
                <Button size={isMobile() ? "normal" : "big"}>
                  <IntlText id="pages.home.contact.button" />
                </Button>
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6 my-5 m-md-0">
            <StaticImage
              src="../images/satellite.png"
              alt={CONTACT_US_ALT}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default HomeContact
