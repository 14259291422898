import React from "react"
import styled from "styled-components"
import { SGGradient } from "../global-styles"

const Icon = styled.a`
  & i {
    padding: 5px 0;
  }
  &:hover {
    & i {
      background: ${SGGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`

const SocialIcon = ({ href, title, icon }) => {
  return (
    <Icon rel="noopener noreferrer" href={href} target="_blank" title={title}>
      <i className={`fa fab fa-lg ${icon}`} aria-hidden="true" />
    </Icon>
  )
}

export default SocialIcon
