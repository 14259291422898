import React, { useContext, useEffect, useRef, useState } from "react"
import styled, { withTheme } from "styled-components"
import { Link } from "gatsby"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"

import { breakpoints } from "../theme"

import HeaderMobileButton from "./header-mobile-button"
import HeaderNavigation from "./header-navigation"
import useResizeEvent from "../hooks/useResizeEvent"
import useScrollEvent from "../hooks/useScrollEvent"
import { addBodyClass, isMobile, removeBodyClass } from "../utils"

import Instagram from "./social-network/instagram"
import Facebook from "./social-network/facebook"
import LinkedIn from "./social-network/linkedin"
import Medium from "./social-network/medium"
import Twitter from "./social-network/twitter"

import "./header.scss"

const Wrapper = styled.header`
  background-color: transparent;
  height: 56px;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.medium}) {
    height: 90px;
  }
`
const Logo = styled.img`
  height: 29px;

  @media (min-width: ${breakpoints.medium}) {
    height: 40px;
  }
`
const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  & a {
    margin-bottom: 40px;
  }
  @media (min-width: ${breakpoints.medium}) {
    margin-right: 34px;
    margin-top: 0;
    flex-direction: row;
    & a {
      margin-right: 40px;
      margin-bottom: 0;
      &.last {
        margin-right: 0;
      }
      &.contact span {
        font-size: 15px;
      }
    }
  }
  @media (min-width: ${breakpoints.extralarge}) {
    & a {
      margin-right: 55px;
    }
    & span {
      border-bottom-width: 4px !important;
    }
  }
`
const Moon = styled.img`
  width: 24px;
  @media (min-width: ${breakpoints.medium}) {
    width: 28px;
  }
`
const TogglerContainer = styled.div`
  margin-left: 25px;
  z-index: 4;
`
const MobileMenu = styled.div`
  background-color: ${props => props.theme.body};
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  transition: all 0.25s linear;
  padding: 20px 30px;
`

const Header = ({ theme }) => {
  const themeContext = useContext(ThemeManagerContext)

  const lastScroll = useRef(0)

  const [isScrollingToBottom, setIsScrollingToBottom] = useState(false)
  const [isScrollingToTop, setIsScrollingToTop] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const handleResize = e => {
    if (!isMobile()) {
      setIsOpen(false)
    }
  }

  const handleScroll = () => {
    const currentScroll = window.pageYOffset
    const scrollingToBottom = currentScroll > lastScroll.current
    setIsScrollingToBottom(scrollingToBottom)
    setIsScrollingToTop(
      currentScroll !== 0 && currentScroll < lastScroll.current
    )
    if (currentScroll >= 0) {
      lastScroll.current = currentScroll
    }
    if (scrollingToBottom) {
      setIsActive(currentScroll > window.innerHeight)
    }
  }

  useResizeEvent(handleResize)
  useScrollEvent(handleScroll)

  useEffect(() => {
    if (window.pageYOffset > 0) {
      setIsScrollingToBottom(true)
    }

    return () => {
      removeBodyClass("no-scroll")
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      addBodyClass("no-scroll")
    } else {
      removeBodyClass("no-scroll")
    }
  }, [isOpen])

  return (
    <header className="page-header">
      <nav
        className={`sg-header${isOpen ? " opened" : ""}${
          isScrollingToBottom ? " sg-header-hidden" : ""
        }${isScrollingToTop ? " scroll-up" : ""}${
          isActive ? " scroll-active" : ""
        }${theme.isDark ? " dark" : ""}`}
        style={{ backgroundColor: theme.body }}
      >
        <Wrapper>
          <div className="container">
            <div className="row">
              <div
                className="col-8 d-flex align-items-center col-md-3"
                style={{ zIndex: 4 }}
              >
                <Link to="/">
                  {!isOpen ? (
                    <Logo
                      src={require(`src/images/${theme.SGLogo}`)}
                      alt="sysgarage"
                    />
                  ) : (
                    <Logo
                      className="small"
                      src={require(`src/images/${theme.SGLogoOpen}`)}
                      alt="sysgarage"
                    />
                  )}
                </Link>
              </div>

              <div className="col-4 col-md-9 d-flex align-items-center justify-content-end">
                <Navigation className="d-none d-md-flex align-items-md-center justify-content-md-end">
                  <HeaderNavigation />
                </Navigation>
                <button onClick={() => themeContext.toggleDark()}>
                  <Moon
                    src={require(`src/images/${theme.moonIcon}`)}
                    alt="dark-mode"
                    className="moon"
                  />
                </button>
                <TogglerContainer className="d-md-none">
                  <HeaderMobileButton
                    color={theme.text}
                    onClick={() => setIsOpen(open => !open)}
                    isOpen={isOpen}
                  />
                </TogglerContainer>
              </div>
            </div>
          </div>
          {isOpen && (
            <MobileMenu className="d-flex flex-column">
              <Navigation>
                <HeaderNavigation />
              </Navigation>
              <div className="mt-auto d-flex justify-content-between">
                <Instagram />
                <Facebook />
                <Twitter />
                <LinkedIn />
                <Medium />
              </div>
            </MobileMenu>
          )}
        </Wrapper>
      </nav>
    </header>
  )
}

export default withTheme(Header)
