const colors = {
  white: "#fff",
  black: "#000",
  lightBg: "#fafafa",
  darkBg: "#eee",
  darkText: "#8890A1",
  lightText: "#fff",
  darkGray: "#1f1f1f",
  pink: "#f3cecd",
  buttonDisabledLight: "#d5dae5",
  buttonDisabledDark: "#d5dae5",
}

const breakpoints = {
  small: "576px",
  medium: "768px",
  large: "992px",
  extralarge: "1200px",
}

const lightTheme = {
  body: colors.lightBg,
  text: colors.black,
  moonIcon: "moon-light.svg",
  SGLogo: "sg-logo-black.svg",
  SGLogoOpen: "sg-logo-small-black.svg",
  headerLink: "dark",
  headerContactButton: "dark",
  homeSectionDescription: colors.black,
  homeSectionChipText: colors.lightText,
  homeSectionButton: "dark",
  sliderArrowColor: colors.black,
  footer: {
    background: colors.black,
    linkColor: "light",
    SGLogo: "sg-logo-small-white.svg",
    titleColor: colors.white,
    buttonColor: "light",
  },
  reviewCard: {
    background: colors.white,
    title: colors.black,
    body: colors.darkText,
    authorJob: colors.darkText,
  },
  buttonColor: "dark",
  highlightProcessBox: {
    bgColor: colors.black,
    textColor: colors.white,
  },
  homeStaffImage: "home/light/staff-augmentation.png",
  homeProductImage: "home/light/product-delivery.png",
  notFoundImage: "home/light/notFound.png",
  image500:"home/light/500.png",
  bannerImage: "home/light/home-banner-2500x1562.png",
  freeTrialImage: "free-trial.png",
}

const darkTheme = {
  body: colors.black,
  text: colors.white,
  moonIcon: "moon.svg",
  SGLogo: "sg-logo-white.svg",
  SGLogoOpen: "sg-logo-small-white.svg",
  headerLink: "light",
  headerContactButton: "light",
  homeSectionDescription: colors.white,
  homeSectionChipText: colors.black,
  homeSectionButton: "light",
  sliderArrowColor: colors.white,
  footer: {
    background: colors.white,
    linkColor: "dark",
    SGLogo: "sg-logo-small-black.svg",
    titleColor: colors.black,
    buttonColor: "dark",
  },
  reviewCard: {
    background: colors.darkGray,
    title: colors.white,
    body: colors.white,
    authorJob: colors.white,
  },
  buttonColor: "light",
  highlightProcessBox: {
    bgColor: colors.white,
    textColor: colors.black,
  },
  homeStaffImage: "home/dark/staff-augmentation.png",
  homeProductImage: "home/dark/product-delivery.png",
  image500:"home/dark/500.png",
  freeTrialImage: "free-trial-dark.png",
  notFoundImage: "home/dark/notFound.png",
  bannerImage: "home/dark/home-banner-2500x1562.png"
}

module.exports = { colors, breakpoints, lightTheme, darkTheme }
