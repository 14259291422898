import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Button from "../button"
import SGLink from "../sg-link"
import IntlText from "../intl-text"
import { withTheme } from "styled-components"

const HeaderNavigation = ({ theme }) => {
  return (
    <>
      <SGLink color={theme.headerLink} size="big" to="/services">
        <IntlText id="header.services" />
      </SGLink>
      <SGLink color={theme.headerLink} size="big" to="/projects">
        <IntlText id="header.projects" />
      </SGLink>
      <SGLink color={theme.headerLink} size="big" to="/culture">
        <IntlText id="header.culture" />
      </SGLink>
      <Link to="/contact" className="last contact">
        <Button color={theme.headerContactButton}>
          <IntlText id="header.contact" />
        </Button>
      </Link>
    </>
  )
}

HeaderNavigation.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(HeaderNavigation)
