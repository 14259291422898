import { createGlobalStyle, css } from "styled-components"
import { breakpoints } from "../theme"

export const headline1 = css`
  font-size: 55px;
  line-height: 65px;
  letter-spacing: 0.3px;
  font-weight: 500;
`

export const title1 = css`
  font-size: 30px;
  line-height: 51px;
  letter-spacing: 0.3px;
  font-weight: 600;
`

export const title1_Emphasis = css`
  font-weight: 500;
  font-size: 37px;
  line-height: 50px;
  letter-spacing: 0.3px;
`

export const title2 = css`
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 0.3px;
`

export const title2_Emphasis = css`
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.3px;
`

export const callout = css`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
`

export const caption = css`
  font-weight: 500;
  font-size: 9px;
  line-height: 20px;
  letter-spacing: 1.4px;
`

export const body1 = css`
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.3px;
`

export const body1_Emphasis = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.3px;
`

export const body2 = css`
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.15px;
`

export const SGGradient = `
  linear-gradient(
    117.41deg,
    #e85beb -36.95%,
    #ff7c43 75.46%,
    #ff4d00 199.32%
  )
`

export const button_medium = css`
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
`

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }

  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }

  .headline1 {
    ${headline1}
  }

  .title1 {
    ${title1}
  }

  .title1-Emphasis {
    ${title1_Emphasis}
  }

  .title2 {
    ${title2}
  }

  .title2-Emphasis {
    ${title2_Emphasis}
  }

  .callout {
    ${callout}
  }

  .caption {
    ${caption}
  }

  .body1 {
    ${body1}
  }

  .body1-Emphasis {
    ${body1_Emphasis}
  }

  .body2 {
    ${body2}
  }

  .button-medium {
    ${button_medium}
  }

  .sg-text-gradient {
    background: ${SGGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .section-container {
    margin-top: 90px;
    @media (min-width: ${breakpoints.medium}) {
      margin-top: 120px;
    }
  }

  .section-subtitle {
    ${body1_Emphasis};
    margin-top: -24px;
    margin-bottom: 32px;
  }
`
