import React from "react"
import SocialIcon from "./social-icon"

const Facebook = () => {
  return (
    <SocialIcon
      href="https://www.facebook.com/sysgarage/"
      title="Sysgarage - Facebook"
      icon="fa-facebook"
    />
  )
}

export default Facebook
