export const AGITA_GYM_ALT = "AgitaGym - Personal meal plans designed just for you";
export const BASAPP_ALT = "Basapp - Be Safer with Basapp";
export const DERMACLINIC_ALT = "Improving our client’s experience";
export const FUDEX_ALT = "Fudex - The power to order is at your fingertips";
export const GLOBAL_TILL_ALT = "GlobalTill - A customized product to run retail stores";
export const ZYN_ALT = "ZYN - A Wine Market with AmaZYN Prices";
export const STAFF_AUGMENTATION_ALT = "App Development: Staff Augmentation";
export const PRODUCT_DELIVERY_TEAM_ALT = "App Development: Product Delivery Team";
export const CONTACT_US_ALT = "Have an idea for an app? Contact us now! - Sysgarage";
export const NOT_FOUND_ALT = "Not found page"
export const BANNER_ALT = "Web and Mobile App Development Services";