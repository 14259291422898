import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { colors } from "../../theme"
import { SGGradient } from "../global-styles"

const borderWidth = "2px"
const borderRadius = "38px"

const paddings = {
  small: {
    vertical: "9px",
    horizontal: "18px",
  },
  medium: {
    vertical: "12px",
    horizontal: "24px",
  },
  big: {
    vertical: "15px",
    horizontal: "34px",
  },
}

const ButtonLayout = styled.button`
  border-radius: ${borderRadius};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not([disabled]) {
    cursor: pointer;
  }

  &.small {
    padding: ${paddings.small.vertical} ${paddings.small.horizontal};
    font-size: 11px;
    span {
      line-height: 17px;
    }
  }
  &.normal {
    padding: ${paddings.medium.vertical} ${paddings.medium.horizontal};
    font-size: 13px;
  }
  &.big {
    padding: ${paddings.big.vertical} ${paddings.big.horizontal};
    font-size: 15px;
  }

  & .text {
    z-index: 4;
    font-weight: 600;
  }

  &.filled,
  &.outlined {
    overflow: hidden;
    position: relative;
    transition: 0.9s;

    &::after {
      background: ${SGGradient};
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: "";
      border-radius: 50%;
      width: 0;
      height: 0;
      transition: all 0.5s;
    }

    &.dark {
      background: ${colors.black};
      color: ${colors.white};
      &:disabled {
        background: ${colors.buttonDisabledDark} !important;
      }
    }
    &.light {
      background: ${colors.white};
      color: ${colors.black};
      &:disabled {
        background: ${colors.buttonDisabledLight} !important;
        &:hover {
          color: ${props => props.theme.body};
        }
      }
    }

    &:hover:not([disabled]) {
      &::after {
        width: 300px;
        height: 300px;
      }
    }
  }
  &.filled {
    transition: color 0.1s;
    &.light:hover {
      color: ${colors.white};
      transition: color 0.1s;
    }
  }
  &.outlined {
    padding: ${borderWidth};

    & .text {
      border-radius: ${borderRadius};
      background: ${props => props.bgColor};
    }

    &.small {
      & .text {
        padding: calc(${paddings.small.vertical} - ${borderWidth})
          calc(${paddings.small.horizontal} - ${borderWidth});
      }
    }
    &.medium {
      & .text {
        padding: calc(${paddings.medium.vertical} - ${borderWidth})
          calc(${paddings.medium.horizontal} - ${borderWidth});
      }
    }
    &.big {
      & .text {
        padding: calc(${paddings.big.vertical} - ${borderWidth})
          calc(${paddings.big.horizontal} - ${borderWidth});
      }
    }
    &.dark {
      color: ${colors.black};
    }

    &.light {
      color: ${colors.white};
    }
  }
  &.text {
    padding: 0;
    background-color: transparent;

    &.dark {
      color: ${colors.black};
    }
    &.light {
      color: ${colors.white};
    }

    &:hover:not([disabled]) {
      span {
        background: ${SGGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &:disabled {
      color: ${colors.buttonDisabledDark} !important;
    }
  }
`

const Button = ({
  variant = "filled",
  color = "dark",
  size = "normal",
  bgColor = "#fafafa",
  children,
  ...rest
}) => {
  return (
    <div>
      <ButtonLayout
        className={`${variant} ${color} ${size}`}
        bgColor={bgColor}
        {...rest}
      >
        <span className="text">{children}</span>
      </ButtonLayout>
    </div>
  )
}

Button.propTypes = {
  variant: PropTypes.oneOf(["filled", "outlined", "text"]),
  color: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["small", "normal", "big"]),
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Button
