import React from "react"
import SocialIcon from "./social-icon"

const Instagram = () => {
  return (
    <SocialIcon
      href="https://www.instagram.com/sysgarage/"
      title="Sysgarage - Instagram"
      icon="fa-instagram"
    />
  )
}

export default Instagram
