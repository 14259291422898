import React from "react"
import SocialIcon from "./social-icon"

const Twitter = () => {
  return (
    <SocialIcon
      href="https://twitter.com/sysgarage"
      title="Sysgarage - Twitter"
      icon="fa-twitter"
    />
  )
}

export default Twitter
