export const isServer = () => {
  return typeof window === 'undefined';
}

export const getWindowDimensions = () => {
  if (isServer()) {
    return { width: null, height: null };
  }
  return {
    width: window.innerWidth || document.documentElement.clientWidth,
    height: window.innerHeight || document.documentElement.clientHeight,
  };
};

export const isMobile = () => {
  const { width } = getWindowDimensions();
  return width < 768;
}

export const addBodyClass = (className) => {
  document.body.classList.add(className);
};

export const removeBodyClass = (className) => {
  document.body.classList.remove(className);
};