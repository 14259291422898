import React, { useEffect } from "react"
import styled, { useTheme } from "styled-components"
import {WOW} from "wowjs"
import { breakpoints } from "../theme"
import { GlobalStyles } from "./global-styles"
import Header from "./header"
import HomeContact from "./home-contact"
import Footer from "./footer"

import "bootstrap/dist/css/bootstrap.min.css"
import "src/libs/font-awesome/css/font-awesome.min.css"
import "src/libs/animate/animate.css"

import "src/styles/_general.scss"
import SEO from "./seo"

const Container = styled.div`
  padding-top: ${props => (props.noMargin ? "0px!important" : "56px")};
  @media (min-width: ${breakpoints.medium}) {
    padding-top: 90px;
  }
`

function Layout({
  pageTitle,
  title,
  description,
  hideContact,
  hideHeader,
  noMargin,
  children,
}) {
  const { isDark } = useTheme();

  useEffect(() => {
    new WOW({ live: false }).init()
  }, [])

  return (
    <>
      <GlobalStyles />
      <SEO
        lang="en"
        pageTitle={pageTitle}
        title={title}
        description={description}
      />

      {!hideHeader && <Header />}
      <Container noMargin={noMargin} className={isDark ? "theme-dark" : ""}>{children}</Container>
      {!hideContact && <HomeContact noMargin={noMargin} />}
      <Footer />
    </>
  )
}

export default Layout
