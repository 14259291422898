import React from "react"
import PropTypes from "prop-types"

import "./index.scss"

const HeaderMobileButton = ({ color, isOpen, onClick }) => {
  return (
    <div className="mobile-btn" onClick={onClick}>
      <span
        style={{ backgroundColor: color }}
        className={`line first ${isOpen ? "opened" : "closed"}`}
      ></span>
      <span
        style={{ backgroundColor: color }}
        className={`line last ${isOpen ? "opened" : "closed"}`}
      ></span>
    </div>
  )
}

HeaderMobileButton.propTypes = {
  color: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
}

export default HeaderMobileButton
