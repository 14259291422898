import React from "react"
import styled from "styled-components"
import { breakpoints } from "../theme"

const Wrapper = styled.div`
  margin-bottom: 128px;
  & a,
  span {
    margin-bottom: 20px;
  }
  @media (min-width: ${breakpoints.medium}) {
    margin-bottom: 0;
  }
`

const FooterSection = ({ children, ...rest }) => {
  return (
    <Wrapper
      className="col-12 d-flex flex-column align-items-center col-md-4 align-items-md-start col-xl-3"
      {...rest}
    >
      {children}
    </Wrapper>
  )
}

export default FooterSection
