import React from "react"
import { useIntl } from "gatsby-plugin-intl"

//use only with page-header
const IntlText = ({ id }) => {
  const intl = useIntl()
  const text = id
    ? intl.formatMessage(
        { id },
        {
          strong: (...chunks) => <strong>{chunks}</strong>,
        }
      )
    : ""

  return text
}

export default IntlText
