import React from "react"
import SocialIcon from "./social-icon"

const LinkedIn = () => {
  return (
    <SocialIcon
      href="https://www.linkedin.com/company/5375553/"
      title="Sysgarage - LinkedIn"
      icon="fa-linkedin"
    />
  )
}

export default LinkedIn
