import React from "react"
import SocialIcon from "./social-icon"

const Medium = () => {
  return (
    <SocialIcon
      href="https://medium.com/@sysgarage"
      title="Sysgarage - Medium"
      icon="fa-medium"
    />
  )
}

export default Medium
