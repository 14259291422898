import React from "react"
import styled from "styled-components"
import { colors } from "../../theme"

const Layout = styled.span`
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.16px;

  &.small {
    font-size: 11px;
  }
  &.medium {
    font-size: 13px;
  }
  &.big {
    font-size: 15px;
  }

  &.dark {
    color: ${colors.black};
  }
  &.light {
    color: ${colors.white};
  }
`

const LinkLayout = ({ children, ...rest }) => {
  return <Layout {...rest}>{children}</Layout>
}

export default LinkLayout
