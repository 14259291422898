import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import { SGGradient } from "../global-styles"
import LinkLayout from "./layout"

const Hover = styled.span`
  padding-bottom: 6px;
  line-height: 20px;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 3px;
    background: ${SGGradient};
    transition: width 0.3s;
  }

  &:hover::after {
    width: 100%;
    //transition: width .3s;
  }
`

const SGLink = ({
  color = "dark",
  size = "medium",
  to = "#",
  router = true,
  hideHover,
  children,
  ...rest
}) => {
  return router ? (
    <Link to={to}>
      <LinkLayout className={`${color} ${size}`} {...rest}>
        <Hover>{children}</Hover>
      </LinkLayout>
    </Link>
  ) : (
    <a href={to}>
      <LinkLayout className={`${color} ${size}`} {...rest}>
        <Hover>{children}</Hover>
      </LinkLayout>
    </a>
  )
}

SGLink.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["small", "medium", "big"]),
  to: PropTypes.string,
  bgColor: PropTypes.string,
  router: PropTypes.bool,
  hideHover: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default SGLink
