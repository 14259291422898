import React from "react"
import styled, { withTheme } from "styled-components"

import Instagram from "./social-network/instagram"
import Facebook from "./social-network/facebook"
import LinkedIn from "./social-network/linkedin"
import Medium from "./social-network/medium"
import Twitter from "./social-network/twitter"
import { breakpoints } from "../theme"
import { title2_Emphasis } from "./global-styles"
import FooterSection from "./footer-section"
import SGLink from "./sg-link"
import LinkLayout from "./sg-link/layout"
import IntlText from "./intl-text"
import { Link } from "gatsby"

const Wrapper = styled.div`
  background-color: ${props => props.theme.footer.background};
  padding: 128px 16px 68px;
  min-height: 100vh;

  & .logo-container {
    display: none;
    @media (min-width: ${breakpoints.extralarge}) {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.medium}) {
    padding-top: 114px;
    padding-bottom: 114px;
    min-height: auto;
  }

  @media (min-width: ${breakpoints.extralarge}) {
    min-height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }
`
const Title = styled.h4`
  ${title2_Emphasis};
  color: ${props => props.theme.footer.titleColor};
  margin-bottom: 40px;
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 0;
    margin-bottom: 59px;
  }
`
const Social = styled.div`
  margin-top: 128px;
  color: ${props => props.theme.footer.titleColor};
  & a {
    color: ${props => props.theme.footer.titleColor};
    & i {
      font-size: 20px;
      padding: 5px 2px;
    }
  }
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 96px;
    order: 1;
    & a {
      margin-right: 32px;
    }
  }
`

const Footer = ({ theme }) => {
  return (
    <Wrapper>
      <div className="container">
        <div className="row">
          <FooterSection>
            <Title>
              <IntlText id="footer.company" />
            </Title>
            {/* <SGLink color={theme.footer.linkColor}>
              <IntlText id="footer.brand" />
            </SGLink> */}
            <SGLink color={theme.footer.linkColor} to="/culture">
              <IntlText id="footer.culture" />
            </SGLink>
            <SGLink color={theme.footer.linkColor} to="/services">
              <IntlText id="footer.services" />
            </SGLink>
            <SGLink color={theme.footer.linkColor} to="/projects">
              <IntlText id="footer.projects" />
            </SGLink>
            {/* <SGLink color={theme.footer.linkColor}>
              <IntlText id="footer.blog" />
            </SGLink> */}
          </FooterSection>
          {/* <FooterSection>
            <Title>
              <IntlText id="footer.help" />
            </Title>
            <SGLink color={theme.footer.linkColor}>
              <IntlText id="footer.faq" />
            </SGLink>
            <SGLink color={theme.footer.linkColor}>
              <IntlText id="footer.commercial_policy" />
            </SGLink>
            <SGLink color={theme.footer.linkColor}>
              <IntlText id="footer.legal_agreement" />
            </SGLink>
            <SGLink color={theme.footer.linkColor}>
              <IntlText id="footer.privacy_policy" />
            </SGLink>
          </FooterSection> */}
          <FooterSection>
            <Title>
              <IntlText id="footer.contact" />
            </Title>
            <LinkLayout className={`${theme.footer.linkColor} medium`}>
              <IntlText id="company_address" />
            </LinkLayout>
            <SGLink
              color={theme.footer.linkColor}
              router={false}
              to="mailto:projects@sysgarage.com"
            >
              <IntlText id="company_email" />
            </SGLink>
            <SGLink
              color={theme.footer.linkColor}
              router={false}
              to="tel:+541156895955"
            >
              <IntlText id="company_phone" />
            </SGLink>
          </FooterSection>

          <div className="d-none d-xl-flex col-xl-3 align-items-xl-start" />

          <FooterSection className="d-none d-xl-flex col-xl-3 align-items-xl-start">
            <div className="logo-container">
              <Link to="/" aria-label="Home - Sysgarage" className="footer-logo-link">
                <img
                  src={require(`src/images/${theme.footer.SGLogo}`)}
                  alt="sysgarage"
                />
                <img
                  src={require(`src/images/sg-logo-small-hover.svg`)}
                  alt="sysgarage"
                  className="footer-logo-hover"
                />
              </Link>
            </div>
          </FooterSection>
        </div>
        <div className="row">
          {/* <ButtonsWrapper className="col-12 d-flex flex-column align-items-center col-md-6 flex-md-row">
            <Button
              size="small"
              variant="outlined"
              bgColor={theme.footer.background}
              color={theme.footer.buttonColor}
            >
              <IntlText id="footer.subscribe" />
            </Button>
            <CarrierButtonContainer>
              <Button
                size="small"
                variant="outlined"
                bgColor={theme.footer.background}
                color={theme.footer.buttonColor}
              >
                <IntlText id="footer.careers" />
              </Button>
            </CarrierButtonContainer>
          </ButtonsWrapper> */}
          <Social className="col-12 d-flex align-items-center justify-content-between col-md-6 justify-content-md-start">
            <Instagram />
            <Facebook />
            <Twitter />
            <LinkedIn />
            <Medium />
          </Social>
        </div>
      </div>
    </Wrapper>
  )
}

export default withTheme(Footer)
